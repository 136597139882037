$project-border: #8d8d8d;
$project-header: #f3f3f3;
$project-highlight: #ebf3a0;

iframe {
  border: 0;
}

footer {
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid black;
  text-align: center;

  img {
    max-height: 3em;
    border-radius: 6px;
  }
}

html {
  padding-bottom: 6em;
}

#header {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: .6em;
  border-bottom: 1px solid #d3d3d3;
}

#me-img {
  border-radius: .5em;
}

.img-responsive {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .text-sm-right {
    text-align: right;
  }
}

.project {
  &:first-child {
    border-top: 1px solid $project-border;
  }
  
  &:target .project-header {
    background-color: $project-highlight;
  }

  border-bottom: 2px solid $project-border;
  //border-left: none;
  //border-right: none;
  //border-bottom-width: 1px;
  //border-radius: .2em;
  //margin-bottom: 1em;

  h4 {
    margin-bottom: .1em;
    margin-top: .3em;
  }

  img.git {
    max-height: 1em;
  }

  .date {
    font-size: .7em;
    color: gray;
  }

  .tags {
    padding-bottom: 2px;
  }

  .project-header {
    background-color: $project-header;
    //border-bottom: 1px solid $project-border;
  }

  .project-description {
    padding: 0 1em;

    ul, p {
      margin-bottom: 2px;
    }
  }
}

.icons_license {
  color: #a1a1a1;
  a {
    color: #7c7c7c;
    text-decoration: underline;
  }
}

.portfolio-nav {
  border-top: 1px solid $project-border;
  border-bottom: 1px solid $project-border;
}